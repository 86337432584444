import { useEffect, useState } from 'react';
import '../css/QROverlay.css'
import Popup from './Popup';

function QRCodeOverlay(props) {

    const [viewPortValue, setViewportValue] = useState("vh");

    useEffect(() => {
        // If anyone has any doubt about how shit Apple's software is, they can
        // check this dumb ass workaround.

        // Handle Safari's old ass way of checking the orientation.
        if (props.isMobileSafari) {
            const portrait = window.matchMedia("(orientation: portrait)").matches;
            setViewportValue(portrait ? 'vh' : 'vw');

            window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
                const portrait = e.matches;
                setViewportValue(portrait ? 'vh' : 'vw');
            });

            return;
        }

        setViewportValue(window.screen.orientation.type.includes("portrait") ? 'vh' : 'vw');
        window.addEventListener('orientationchange', () => { setViewportValue(window.screen.orientation.type.includes("portrait") ? 'vh' : 'vw') });
    }, [])

    return (
        <div className='OverlayContainer'>
            <div className='SquareCutout'></div>
            <div className='SquareIndicator'></div>
            <Popup
                onClick={props.onClick}
                style={{
                    width: `30${viewPortValue}`,
                    height: "fit-content"
                }}
                title={"Welcome !"}
                content={"Scan one of the QR Codes you'll find in \nthe production line to read its details."}
            />
        </div>
    );
}

export default QRCodeOverlay;