import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App'; // Import your main App component
import './index.css';

const root = createRoot(document.getElementById('root'));

const RootComponent = (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/machines/:id?" element={<App />} />
      <Route path="/production_line/:id?" element={<App />} />
    </Routes>
  </Router>
);

root.render(RootComponent);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
