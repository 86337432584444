import './App.css';
import { useEffect, useRef, useState } from 'react';
import { QrScanner } from '@yudiel/react-qr-scanner';
import { useParams } from 'react-router-dom';
import { isMobileSafari } from 'react-device-detect';
import { Helmet } from 'react-helmet';

import QRCodeOverlay from './components/QRCodeOverlay';
import DataPopup from './components/DataPopup';
import FieldRow from './components/FieldRow';
import Loader from './components/Loader';

import { getMachine, getProductionLine } from './consts';
import HenkelContainer from './components/HenkelContainer';
import FieldColumn from './components/FieldColumn';

const displayStates = {
  None: "none",
  Machine: "machine",
  ProductionLine: "productionLine"
}

const options = {
  year: 'numeric',
  // month: 'long',
  // day: 'numeric',
  // hour: 'numeric',
  // minute: 'numeric',
  // second: 'numeric',
  timeZone: 'Africa/Algiers' // Adjust as needed
};

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', options);
}

function App(props) {

  // State of the page, should change when the user needs to use the camera.
  const [isCameraMode, setIsCameraMode] = useState(false);
  const [vh, setVH] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [viewPortValue, setViewportValue] = useState("vh");
  const [dataDisplayState, setDataDisplayState] = useState(displayStates.None);

  const [page, setPage] = useState(1);

  const [machineData, setMachineData] = useState({
    _id: "",
    name: "",
    breakdown_count: 0,
    production_line: {}, // Entire data of a production line
    recurring_breakdown_reason_history: [""],
    equipment_id: 123456,
    equipment_descriptor: "",
    equipment_local: "",
    manufacturer: "",
    model: "",
    serial_number: "",
    asset_number: "",
    functional_location: "",
    operating_location: "",
    psv_1: "",
    speed_1: "",
    psv_2: "",
    speed_2: "",
    construction_date: "",
    last_maintenance_date: ""
  });

  const [productionLineData, setProductionLineData] = useState({
    _id: "",
    name: "",
    totalBreakdowns: 0,
    production_line: "",
    recurring_breakdown_reason: [],
    installation_date: "",
    date_of_commissioning: "",
    // last_maintenance_date: "",
    machines: []
  });

  var permissionCheckInterval = null;

  const { id } = useParams();

  const machineRef = useRef(null);
  const productionLineRef = useRef(null);

  function checkCameraPermission() {
    
    if (!isLoading) {
      clearInterval(permissionCheckInterval)
    }
    // Check if camera permissions are granted to change the loading state
    if (typeof navigator.permissions === "undefined") {
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      clearInterval(permissionCheckInterval);
      return;
    }

    navigator.permissions.query({ name: 'camera' })
      .then(permissionStatus => {
        
        if (permissionStatus.state === 'granted') {
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
          clearInterval(permissionCheckInterval);
          // Do something when permission is granted
        }
        else if (permissionStatus.state === 'prompt') {
          return;
        }
        else {
          console.log('Camera permission not granted');
          
          // TODO: Notify the user that he should grant the camera
          // persmission to the page

        }

      })
      .catch(error => {
        console.error('Error checking camera permission:', error);
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
        clearInterval(permissionCheckInterval);
      });
  }

  useEffect(() => {

    setVH(window.innerHeight * 0.01);
    document.documentElement.style.setProperty('--vh', `${vh}px`);

  });

  useEffect(() => {

    // If anyone has any doubt about how shit Apple's software is, they can
    // check this dumb ass workaround.

    // Handle Safari's old ass way of checking the orientation.
    if (isMobileSafari) {
      const portrait = window.matchMedia("(orientation: portrait)").matches;
      setViewportValue(portrait ? 'vh' : 'vw');

      window.matchMedia("(orientation: portrait)").addEventListener("change", e => {
        const portrait = e.matches;
        setViewportValue(portrait ? 'vh' : 'vw');
      });

      return;
    }

    setViewportValue(window.screen.orientation.type.includes("portrait") ? 'vh' : 'vw');
    window.addEventListener('orientationchange', () => {setViewportValue(window.screen.orientation.type.includes("portrait") ? 'vh' : 'vw')});
  }, [])

  useEffect(() => {

    if (permissionCheckInterval === null) {
      permissionCheckInterval = setInterval(checkCameraPermission, 600);
    }

    if (!id) {
      setIsCameraMode(true);
      return;
    }

    if (window.location.href.includes("machines")) {
      setDataDisplayState(displayStates.Machine);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id })
      };

      fetch(getMachine, requestOptions)
        .then((response) => response.json())
        .then(data => {
          setMachineData(data.data);
          setIsDataFetched(true);
        });
    }

    if (window.location.href.includes("production_line")) {
      setDataDisplayState(displayStates.ProductionLine);

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id })
      };

      fetch(getProductionLine, requestOptions)
        .then((response) => response.json())
        .then(data => {
          data.data.recurring_breakdown_reason[0] = ReformatBreakLines(data.data.recurring_breakdown_reason[0]);
          setProductionLineData(data.data);
          setIsDataFetched(true);
        });
    }

  }, [])


  // Simply switches between reading QR codes and showing data
  function SwitchMode() {
    setIsCameraMode(!isCameraMode);
  }

  function ReformatBreakLines(data) {
    return data.split("\\n").join("\n");
  }

  function ReformatName(name) {
    return name.split("_").join(" ");
  }

  function OnScan(data) {
    if (!data || !isCameraMode) {
      return;
    }
    window.location.href = data;
  }

  const constraints = {
    facingMode: 'environment'
  }

  const cameraContainerStyle = {
    height: `${window.innerHeight}px`,
    position: 'absolute',
    padding: 0,
    display: "flex",
    justifyContent: "center"
  }

  return (
    <div className="App">
      <Helmet>
        <title>Henkel : Production Line Scanner</title>
      </Helmet>

      {(isLoading || !isDataFetched) && <Loader/>}
      {!isCameraMode && dataDisplayState === displayStates.Machine && (<>
        <DataPopup
          id="machineRef"
          style={{
            width: `34${viewPortValue}`,
            height: "fit-content",
            margin: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
          setPage={setPage}
          page={page}
        >
          <button
            className='HenkelButton'
            style={{ top: "-84px", right: "0px" }}
            onClick={() => { SwitchMode() }}
          >
            <img src="/QRIcon.png" />
          </button>
          <HenkelContainer primary={true}>
            <h3 style={{ fontFamily: "Inter" }}>{ReformatName(machineData?.name)}</h3>
          </HenkelContainer>
          {page === 1 && <>

            <HenkelContainer primary={false}>
              <FieldColumn label="Local Name" style={{ flexDirection: "column" }} value={machineData?.equipment_local} />
            </HenkelContainer>

            <HenkelContainer primary={false}>
              <FieldColumn label="Construction Year"
                value={formatDate(machineData.construction_date)}
              />
            </HenkelContainer>



          </>}
          {page !== 1 && <>
            <HenkelContainer primary={false}>
              <FieldColumn label="Model"
                value={machineData.model}
              />
            </HenkelContainer>
            <HenkelContainer primary={false}>
              <FieldColumn label="Manufacturer"
                value={machineData.manufacturer}
              />
            </HenkelContainer>
          </>}
        </DataPopup>
      </>)}

      {!isCameraMode && dataDisplayState === displayStates.ProductionLine && (<>

        <DataPopup
          id="productionLineRef"
          style={{
            width: `35${viewPortValue}`,
            height: "fit-content",
            margin: 0,
            justifyContent: "center",
            alignItems: "center"
          }}
          setPage={setPage}
          page={page}
        >
          <button
            className='HenkelButton'
            style={{ top: "-84px", right: "0px" }}
            onClick={() => { SwitchMode() }}
          >
            <img src="/QRIcon.png" />
          </button>

          {page === 1 && <>
            <HenkelContainer primary={true}>
              <h3 >{productionLineData?.name}</h3>
            </HenkelContainer>
            <HenkelContainer primary={false}>
              <FieldRow label="Commissioning Year" value={formatDate(productionLineData?.date_of_commissioning)} />
            </HenkelContainer>
            <HenkelContainer primary={false}>
              <FieldRow label="Recurring Breakdown Causes" style={{ flexDirection: "column" }} value={productionLineData?.recurring_breakdown_reason[0]} />
            </HenkelContainer>
          </>}

          {page === 2 && <>
            <HenkelContainer primary={true}>
              <h3 >{productionLineData?.name}</h3>
            </HenkelContainer>
            <HenkelContainer primary={false} style={{ flexDirection: "column", justifyContent: "flex-start", alignItems: "flex-start" }}>
              <h3 style={{ marginBottom: "12px" }}>List of Machines :</h3>
              <ul style={{ paddingLeft: "24px", margin: "0" }}>
                {productionLineData.machines?.map((element) => {
                  return <li style={{ textAlign: "left", fontWeight: "600", fontSize: "16px" }}>{element.equipment_descriptor}</li>
                })}
              </ul>

            </HenkelContainer>
          </>}

        </DataPopup>
      </>)}
      {/* Camera mode */}
      {isCameraMode && (<>
        <QRCodeOverlay isMobileSafari={isMobileSafari}
        />
      </>)}

      <QrScanner
        containerStyle={cameraContainerStyle}
        videoStyle={{
          width: `100${viewPortValue}`,
          height: `100${viewPortValue}`,
          maxHeight: "100vh",
          overflow: "visible",
          display: "flex",
          position: 'relative'
        }}
        constaints={constraints}
        scanDelay={60}
        onResult={OnScan}
      />

      {/* Read single machine data mode */}
    </div>
  );
}

export default App;
