import '../css/HenkelContainer.css';

function HenkelContainer(props) {

    const style = props.style ? props.style : {};
    if (!props.primary) {
        style.backgroundColor = "#00000070"
        style.fontSize = "14px";
    }
    else {
        style.backgroundColor = "#FFFFFF70"
        style.fontSize = "18px";
    }

    return (
        <div className="HenkelContainer" style={style}>
            {props.children}
        </div>
    )
}

export default HenkelContainer;