import '../css/FieldColumn.css';

function FieldColumn(props) {

    return (
        <div className="FieldColumn" style={props.style}>
            <label style={{marginRight:"4px", fontSize:"1rem", fontWeight:"500", whiteSpace: "pre-line", textAlign:"center"}}>{props.label} : {"\n"}</label>
            <p style={{fontSize:"1rem", textAlign:"right", whiteSpace: "pre-wrap"}}>{props.value}</p>
        </div>
    )
}

export default FieldColumn;