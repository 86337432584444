import '../css/Popup.css'

function Popup(props) {
    return (
        <div onClick={props.onClick} className='PopupContainer' style={props.style}>
            <h2 style={{marginBottom:"1vh"}}>{props.title}</h2>
            <p>{props.content}</p>
        </div>
    );
}

export default Popup;