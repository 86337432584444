import '../css/Popup.css'

function DataPopup(props) {



    return (
        <div id={props.id} onClick={props.onClick} className='PopupContainer' style={props.style}>
            {props.children}
            <div className='Pagination'>
                <button
                    disabled={props.page === 1}
                    onClick={() => props.setPage(1)}
                    className='PaginationButton'
                    style={{ backgroundColor: props.page === 1 ? "#00000070" : "#FFFFFF60" }}
                >
                    <img src='/PageBCK.png' width={"16px"} height={"16px"} />
                </button>

                <div style={{ width: "24px", height: "16px", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                    <i style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: props.page === 1 ? "#FF4338" : "#F8F8F8" }} color='white'></i>
                    <i style={{ width: "8px", height: "8px", borderRadius: "100%", backgroundColor: props.page === 1 ? "#F8F8F8" : "#FF4338" }}></i>
                </div>

                <button
                    disabled={props.page !== 1}
                    onClick={() => props.setPage(2)}
                    className='PaginationButton'
                    style={{ backgroundColor: props.page === 1 ? "#FFFFFF60" : "#00000070" }}
                ><img src='/PageFWD.png' width={"16px"} height={"16px"} /></button>
            </div>
        </div>
    );
}

export default DataPopup;